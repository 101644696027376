/* Dropdown Button */
.langDropbtn {
    background-color: #d0f0fd;
    color: black;
    padding: 10px;
    font-size: 15px;
    border: none;

    border-radius: 100%;

  }
  
  /* The container <div> - needed to position the dropdown content */
  .langDropdown {
      /*
    position: absolute;
    display: inline-block;
    margin-left: 20px;
    margin-top: 120px;
    */
  }
  
  /* Dropdown Content (Hidden by Default) */
  .langDropdown-content {
  
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;

  }
  
  /* Links inside the dropdown */
  .langDropdown-content label {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .langDropdown-content a:hover {background-color: rgb(177, 219, 235);}
  
  /* Show the dropdown menu on hover */
  .langDropdown:hover .langDropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .langDropdown:hover .langDropbtn {background-color: rgb(177, 219, 235);}